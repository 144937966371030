/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import site from "codedrills_proto/io/codedrills/proto/site/content_view_service_grpc_web_pb";
import judge from "codedrills_proto/io/codedrills/proto/judge/judge_service_grpc_web_pb";
import team from "codedrills_proto/io/codedrills/proto/user/team_service_grpc_web_pb";

import { ApiCall } from "@/utils/api.js";
var content_proto = proto.io.codedrills.proto.content;
var site_proto = proto.io.codedrills.proto.site;
var builder_proto = proto.io.codedrills.proto.builder;
var judge_proto = proto.io.codedrills.proto.judge;
var user_proto = proto.io.codedrills.proto.user;

const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);
const judgeService = new judge.JudgeServicePromiseClient(
  process.env.VUE_APP_JUDGE_API_URL + "/judge",
  null,
  null
);
const siteService = new site.ContentViewServicePromiseClient(
  process.env.VUE_APP_SITE_API_URL + "/site",
  null,
  null
);

const teamService = new team.TeamServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const state = {
  content: null,
  view: null,
  loading: null,
  allSubmissions: null,
  fetchAllContestSubmissionsStatus: 0,
  fetchContestScoreboardStatus: 0,
  fetchContestScoreboardAdminStatus: 0,
  scoreboard: null,
  scoreboardAdmin: null,
  refreshScoreboardStatus: 0,
  attachment: null,
  extendContestForTeasmStatus: 0,
  fetchContestTeamsStatus: 0,
  ScoreDetailsForContestStatus: 0,
  dumpContestScoreboardStatus: 0,
};

const actions = {
  async fetchContest({ commit, state, getters, dispatch }, { url }) {
    console.log("TRYING TO FETCH", url);
    commit("toggleLoading", true);

    return dispatch("user/ensureUserToken", null, { root: true }).then(
      (userToken) => {
        var getContentRequest = new builder_proto.GetContentRequest();
        var contentId = new content_proto.ContentId();
        var contentAddress = new content_proto.ContentAddress();
        contentAddress.setContentType(
          proto.io.codedrills.proto.content.ContentType.CONTEST
        );
        contentAddress.setUrl(url);
        contentId.setContentAddress(contentAddress);
        getContentRequest.setContentId(contentId);

        return builderService
          .getContent(getContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setContent", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          })
          .finally(() => {
            commit("toggleLoading", false);
          });
      }
    );
  },

  async updateAttachment({ commit, state, getters, dispatch }, { attachment }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var createAttachmentRequest =
          new builder_proto.CreateAttachmentRequest();
        createAttachmentRequest.setContentId(state.content.getId());
        createAttachmentRequest.setIsPublic(true);
        await attachment.arrayBuffer().then((arrayBuffer) => {
          createAttachmentRequest.setData(new Uint8Array(arrayBuffer));
        });
        createAttachmentRequest.setName(attachment.name);
        createAttachmentRequest.setType(attachment.type);

        console.log("Request", createAttachmentRequest);
        return builderService
          .createAttachment(createAttachmentRequest, {
            Authorization: userToken,
          })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setAttachment", res);
          })
          .catch((err) => {
            console.error("ERROR", err);
          });
      }
    );
  },

  async updateContest({ commit, state, getters, dispatch }) {
    return dispatch("user/ensureUserToken", null, { root: true }).then(
      async (userToken) => {
        var updateContentRequest = new builder_proto.UpdateContentRequest();
        updateContentRequest.setId(state.content.getId());
        updateContentRequest.setTitle(state.content.getTitle());
        updateContentRequest.setVersion(state.content.getVersion());
        updateContentRequest.setData(state.content.getData());
        updateContentRequest.setPublishType(state.content.getPublishType());
        updateContentRequest.setContentType(state.content.getContentType());
        updateContentRequest.setMetaData(state.content.getMetaData());

        console.log("REQUEST", updateContentRequest.toObject());

        return builderService
          .updateContent(updateContentRequest, { Authorization: userToken })
          .then((res) => {
            console.log("SUCCESS", res.toObject());
            commit("setContent", res);
          });
      }
    );
  },

  fetchAllContestSubmissions: new ApiCall("fetchAllContestSubmissions")
    .authRequired()
    .withServiceCall((r, h) => judgeService.contestSubmissions(r, h))
    .withRequest(({ url, paginationQuery }) => {
      console.log("URL ..", url);
      console.log("Pg", paginationQuery);
      return new judge_proto.master.ContestSubmissionsRequest()
        .setContentId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.CONTEST)
          )
        )
        .setPaginationQuery(
          new content_proto.PaginationQuery()
            .setItemsPerPage(paginationQuery.itemsPerPage)
            .setPageIndex(paginationQuery.pageIndex - 1)
        );
    })
    .onSuccess(({ commit }, res) => {
      console.log("submissions: ", res);
      commit("setAllSubmissions", res);
      return res;
    })
    .build(),

  fetchContestScoreboard: new ApiCall("fetchContestScoreboard")
    .withServiceCall((r, h) => siteService.getContestScoreboard(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContestScoreboardRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
      //.setDataType(content_proto.DatatType.SUMMARY)
      // .setPaginationQuery(
      // 	new content_proto.PaginationQuery()
      // 		.setItemsPerPage(paginationQuery.itemsPerPage)
      // 		.setPageIndex(paginationQuery.pageIndex - 1)
      // );
    })
    .onSuccess(({ commit }, res) => commit("setContestScoreboard", res))
    .build(),

  fetchContestScoreboardAdmin: new ApiCall("fetchContestScoreboardAdmin")
    .withServiceCall((r, h) => siteService.getContestScoreboardAdmin(r, h))
    .withRequest(({ url, paginationQuery }) => {
      console.log("pq: ", paginationQuery);
      var paginationRequest = new content_proto.PaginationQuery()
        .setItemsPerPage(paginationQuery.itemsPerPage)
        .setPageIndex(paginationQuery.pageIndex - 1);
      if (paginationQuery.search) {
        paginationRequest.setSearchTermList([
          new content_proto.SearchTerm()
            .setFieldName("name")
            .setFieldValue(paginationQuery.search),
          new content_proto.SearchTerm()
            .setFieldName("org")
            .setFieldValue(paginationQuery.search),
        ]);
      }
      var req = new site_proto.GetContestScoreboardRequest()
        .setContestId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.CONTEST)
          )
        )
        .setPaginationQuery(paginationRequest);
      return req;
    })
    .onSuccess(({ commit }, res) => commit("setContestScoreboardAdmin", res))
    .build(),

  dumpContestScoreboard: new ApiCall("dumpContestScoreboard")
    .withServiceCall((r, h) => siteService.dumpContestScoreboard(r, h))
    .withRequest(({ url }) => {
      return new site_proto.GetContestScoreboardRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => console.log("Uploading done ..."))
    .build(),

  refreshScoreboard: new ApiCall("refreshScoreboard")
    .withServiceCall((r, h) => siteService.refreshScoreboard(r, h))
    .withRequest(({ url }) => {
      return new site_proto.RefreshScoreboardRequest().setContentId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => console.log("refreshing done ..."))
    .build(),

  fetchContestParticipents: new ApiCall("fetchContestParticipents")
    .withServiceCall((r, h) =>
      siteService.getContestSuccessfullParticipants(r, h)
    )
    .withRequest(({ url }) => {
      return new site_proto.GetContestSuccessfullParticipantsRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log("downloaded participants ...", res.toObject());
      return res.getParticipantsCsv();
    })
    .build(),

    fetchNonTeamContestParticipents: new ApiCall("fetchNonTeamContestParticipents")
    .withServiceCall((r, h) =>
      siteService.getContestParticipants(r, h)
    )
    .withRequest(({ url }) => {
      return new site_proto.GetContestParticipantsRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log("downloaded participants ...", res.toObject());
      return res.getParticipantsCsv();
    })
    .build(),

  fetchContestTeams: new ApiCall("fetchContestTeams")
    .withServiceCall((r, h) => teamService.getTeams(r, h))
    .withRequest(({ url }) => {
      return new user_proto.GetTeamsRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log("downloaded participants ...", res.toObject());
      return res;
    })
    .build(),

  extendContestForTeams: new ApiCall("extendContestForTeasm")
    .withServiceCall((r, h) => teamService.extendContestForTeams(r, h))
    .withRequest(({ url, extensionInSecs, teamIds }) => {
      return new user_proto.ExtendContestForTeamsRequest()
        .setContestId(
          new content_proto.ContentId().setContentAddress(
            new content_proto.ContentAddress()
              .setUrl(url)
              .setContentType(content_proto.ContentType.CONTEST)
          )
        )
        .setExtensionInSeconds(extensionInSecs)
        .setTeamIdsList(teamIds);
    })
    .onSuccess(({ commit }, res) => {
      console.log("downloaded participants ...", res.toObject());
      return res;
    })
    .build(),

  ScoreDetailsForContest: new ApiCall("ScoreDetailsForContest")
    .withServiceCall((r, h) => siteService.scoreDetailsForContest(r, h))
    .withRequest(({ url }) => {
      return new site_proto.ScoreDetailsForContestRequest().setContestId(
        new content_proto.ContentId().setContentAddress(
          new content_proto.ContentAddress()
            .setUrl(url)
            .setContentType(content_proto.ContentType.CONTEST)
        )
      );
    })
    .onSuccess(({ commit }, res) => {
      console.log("downloaded participants ...", res.toObject());
      return res;
    })
    .build(),
};

const getters = {
  userToken: function (state, getters, rootState, rootGetters) {
    parameters.addValue("meta_data", content.getMetaData().toByteArray());
    console.log("rootGetters", rootGetters);
    return rootGetters["user/userToken"];
  },
  types: function () {
    return Object.keys(proto.io.codedrills.proto.content.ContentType);
  },
  publishType(state, getters) {
    return getters.publishTypeKeys[state.content.getPublishType()];
  },
  publishTypeKeys() {
    return Object.keys(content_proto.PublishType);
  },
  contestTypeKeys() {
    return Object.keys(content_proto.ContestType);
  },
  nameUniquenessKeys() {
    return Object.keys(content_proto.TeamNameUniqueness);
  },
  presenceKeys() {
    return Object.keys(content_proto.FieldPresence);
  },
  customAttributeKeys() {
    return Object.keys(content_proto.CustomAttributeType);
  },
  contestType(state, getters) {
    return getters.contestTypeKeys[
      state.content.getMetaData().getContestMeta().getContestType()
    ];
  },
  scoreboardType(state, getters) {
    if (!state.content.getData().getContest().getScoreboardSettings()) return 0;
    return getters.scoreboardTypeKeys[
      state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .getScoreboardType()
    ];
  },
  scoreboardPenalty(state, getters) {
    if (!state.content.getData().getContest().getScoreboardSettings()) return 0;
    return state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .getPenaltyPerAttemptInS();
  },
  freezeOffsetTimeInSec(state, getters) {
    if (
      !state.content.getData().getContest().getScoreboardSettings() ||
      !state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .getFreezeSettings()
    )
      return 0;
    return state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .getFreezeSettings()
      .getFreezeOffsetTimeInS();
  },
  unfreeze(state, getters) {
    if (
      !state.content.getData().getContest().getScoreboardSettings() ||
      !state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .getFreezeSettings()
    )
      return false;
    return state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .getFreezeSettings()
      .getUnfreeze();
  },
  scoreboardTypeKeys() {
    console.log("content_proto.ScoreboardType", content_proto.ScoreboardType);
    return Object.keys(content_proto.ScoreboardType);
  },
  submissionVisibilityKeys() {
    return Object.keys(content_proto.SubmissionVisibility);
  },

  submissionVisibilityType(state, getters) {
    return getters.submissionVisibilityKeys[
      state.content.getMetaData().getSubmissionVisibility()
    ];
  },

  scoreboardVisibilityKeys() {
    return Object.keys(content_proto.ScoreboardVisibility);
  },
  testCaseVisibilityKeys() {
    return Object.keys(content_proto.TestCaseVisibility);
  },
  nameUniquenessType(state, getters) {
    if (
      state.content.getMetaData().getContestMeta().getTeamContestConfig() &&
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getTeamNameUniqueness()
    ) {
      return getters.nameUniquenessKeys[
        state.content
          .getMetaData()
          .getContestMeta()
          .getTeamContestConfig()
          .getTeamNameUniqueness()
      ];
    }
  },
};

const mutations = {
  toggleLoading(state, content) {
    state.loading = content;
  },

  setContent(state, res) {
    state.content = res.getContent();
    state.view = res.getContentView();
  },
  setProblems(state, problems) {
    state.content.getData().getContest().setProblemsList(problems);
  },
  setAttachment(state, res) {
    state.attachment = res.getAttachment();
  },
  setSubmissionVisibility(state, visibilty) {
    console.log("Setting submission visibility in vuex ...", visibilty);
    state.content
      .getMetaData()
      .setSubmissionVisibility(content_proto.SubmissionVisibility[visibilty]);
  },
  setScoreboardVisibility(state, visibilty) {
    state.content
      .getMetaData()
      .getContestMeta()
      .setScoreboardVisibility(content_proto.ScoreboardVisibility[visibilty]);
  },
  setTestCaseVisibility(state, visibilty) {
    state.content
      .getMetaData()
      .getContestMeta()
      .setTestCaseVisibility(content_proto.TestCaseVisibility[visibilty]);
  },
  setContestScoreboard(state, res) {
    console.log("Scoreboard response .. ", res);
    state.scoreboard = res.getScoreboardSnapshot();
  },

  setContestScoreboardAdmin(state, res) {
    console.log("Scoreboard response .. ", res);
    state.scoreboardAdmin = res.getScoreboardSnapshot();
  },

  setAllSubmissions(state, allSubmissions) {
    state.allSubmissions = allSubmissions;
  },

  setTitle(state, title) {
    state.content.setTitle(title);
  },

  setAbout(state, about) {
    state.content.getData().getContest().setAbout(about);
  },
  setRules(state, rules) {
    state.content.getData().getContest().setRules(rules);
  },
  setImageUrl(state, url) {
    console.log("URL ", url);
    state.content.getMetaData().setImageUrl(url);
  },
  setBannerUrl(state, url) {
    console.log("set banner URL ", url);
    state.content.getMetaData().getContestMeta().setBannerUrl(url);
  },
  setStartAt(state, datetime) {
    state.content.getMetaData().getContestMeta().setStartAt(datetime);
  },
  setEndAt(state, datetime) {
    state.content.getMetaData().getContestMeta().setEndAt(datetime);
  },
  setScoreboardType(state, scoreboardType) {
    if (!state.content.getData().getContest().getScoreboardSettings()) {
      state.content
        .getData()
        .getContest()
        .setScoreboardSettings(new content_proto.ScoreboardSettings());
    }
    state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .setScoreboardType(content_proto.ScoreboardType[scoreboardType]);
  },
  setScoreboardPenalty(state, penalty) {
    if (!state.content.getData().getContest().getScoreboardSettings()) {
      state.content
        .getData()
        .getContest()
        .setScoreboardSettings(new content_proto.ScoreboardSettings());
    }
    state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .setPenaltyPerAttemptInS(penalty);
  },

  setFreezeOffsetTimeInSecs(state, freezeOffsetTimeInS) {
    if (!state.content.getData().getContest().getScoreboardSettings()) {
      state.content
        .getData()
        .getContest()
        .setScoreboardSettings(new content_proto.ScoreboardSettings());
    }
    if (
      !state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .getFreezeSettings()
    ) {
      state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .setFreezeSettings(new content_proto.FreezeSettings());
    }
    state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .getFreezeSettings()
      .setFreezeOffsetTimeInS(freezeOffsetTimeInS);
  },
  setUnfreeze(state, unfreeze) {
    if (!state.content.getData().getContest().getScoreboardSettings()) {
      state.content
        .getData()
        .getContest()
        .setScoreboardSettings(new content_proto.ScoreboardSettings());
    }
    if (
      !state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .getFreezeSettings()
    ) {
      state.content
        .getData()
        .getContest()
        .getScoreboardSettings()
        .setFreezeSettings(new content_proto.FreezeSettings());
    }
    state.content
      .getData()
      .getContest()
      .getScoreboardSettings()
      .getFreezeSettings()
      .setUnfreeze(unfreeze);
  },

  appendPermissions(state, { ids, role }) {
    console.log("ids and role ..", ids, role);
    for (var id of ids) {
      state.content
        .getMetaData()
        .getPermissionsMap()
        .set(
          id,
          new content_proto.Permission().setAccess(content_proto.Access[role])
        );
    }
    console.log(
      "MetaData with permissions",
      state.content.getMetaData().toObject()
    );
  },
  removePermissions(state, { ids, role }) {
    console.log("ids and role ..", ids, role);
    for (var id of ids) {
      state.content.getMetaData().getPermissionsMap().del(id);
    }
    console.log(
      "MetaData with permissions",
      state.content.getMetaData().toObject()
    );
  },

  setRestrictedDomains(state, domains) {
    console.log("domains", domains);
    state.content.getMetaData().setRestrictedDomainList(domains);
  },

  changePublishType(state, publishType) {
    console.log("Publish Type", publishType);
    state.content.setPublishType(content_proto.PublishType[publishType]);
  },
  changeContestType(state, contestType) {
    state.content
      .getMetaData()
      .getContestMeta()
      .setContestType(content_proto.ContestType[contestType]);
  },
  updateMinTeamSize(state, minTeamSize) {
    if (!state.content.getMetaData().getContestMeta().getTeamContestConfig())
      state.content
        .getMetaData()
        .getContestMeta()
        .setTeamContestConfig(new content_proto.TeamContestConfig());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .setMinTeamSize(minTeamSize);
  },
  updateMaxTeamSize(state, maxTeamSize) {
    if (!state.content.getMetaData().getContestMeta().getTeamContestConfig())
      state.content
        .getMetaData()
        .getContestMeta()
        .setTeamContestConfig(new content_proto.TeamContestConfig());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .setMaxTeamSize(maxTeamSize);
  },
  setTeamEditablity(state, isEditable) {
    if (!state.content.getMetaData().getContestMeta().getTeamContestConfig())
      state.content
        .getMetaData()
        .getContestMeta()
        .setTeamContestConfig(new content_proto.TeamContestConfig());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .setIsEditable(isEditable);
  },
  updateNameUniqueness(state, uniquenessType) {
    if (!state.content.getMetaData().getContestMeta().getTeamContestConfig())
      state.content
        .getMetaData()
        .getContestMeta()
        .setTeamContestConfig(new content_proto.TeamContestConfig());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .setTeamNameUniqueness(content_proto.TeamNameUniqueness[uniquenessType]);
  },
  setIsCoachEnabled(state, isCoachEnabled) {
    if (!state.content.getMetaData().getContestMeta().getTeamContestConfig())
      state.content
        .getMetaData()
        .getContestMeta()
        .setTeamContestConfig(new content_proto.TeamContestConfig());
    if (
      !state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getCoachSetting()
    )
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .setCoachSetting(new content_proto.CoachSetting());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getCoachSetting()
      .setCoachEnabled(isCoachEnabled);
  },
  setCoachPresence(state, presence) {
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getCoachSetting()
      .setCoachPresence(content_proto.FieldPresence[presence]);
  },
  addTeamCustomAttribute(state, cattr) {
    console.log("Name and attribute values are ....", cattr);
    var customAttribute = new content_proto.CustomAttribute();
    customAttribute.setName(cattr.name);
    customAttribute.setCustomAttributeType(
      content_proto.CustomAttributeType[cattr.type]
    );
    customAttribute.setAutoCompleteSetting(
      new content_proto.AutoCompleteSetting().setValueList(cattr.setting)
    );
    customAttribute.setCustomAttributePresence(
      content_proto.FieldPresence[cattr.presence]
    );
    customAttribute.setCustomValueAllowed(cattr.customValueAllowed);
    customAttribute.setOrderIndex(cattr.orderIndex);
    customAttribute.setHelpText(cattr.helpText);
    if (cattr.conditionFieldName) {
      customAttribute.setPresenceCondition(
        new content_proto.PresenceCondition().setPresenceTermList([
          new content_proto.PresenceTerm()
            .setFieldName(cattr.conditionFieldName)
            .setFieldValue(cattr.conditionFieldValue),
        ])
      );
    }
    console.log("Custom Attribute Value Object", customAttribute.toObject());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getTeamCustomAttributeMap()
      .set(cattr.name, customAttribute);
    console.log(
      "Map ..",
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getTeamCustomAttributeMap()
        .get(cattr.name)
    );
  },
  removeTeamCustomAttribute(state, name) {
    console.log("Removing  ...", name);
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getTeamCustomAttributeMap()
      .del(name);
    console.log(
      "Map After removing ..",
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getTeamCustomAttributeMap()
        .get(name)
    );
  },
  addMemberCustomAttribute(state, cattr) {
    console.log("Name and attribute values are ....", cattr);
    var customAttribute = new content_proto.CustomAttribute();
    customAttribute.setName(cattr.name);
    customAttribute.setCustomAttributeType(
      content_proto.CustomAttributeType[cattr.type]
    );
    customAttribute.setAutoCompleteSetting(
      new content_proto.AutoCompleteSetting().setValueList(cattr.setting)
    );
    customAttribute.setCustomAttributePresence(
      content_proto.FieldPresence[cattr.presence]
    );
    customAttribute.setOrderIndex(cattr.orderIndex);
    customAttribute.setHelpText(cattr.helpText);
    if (cattr.conditionFieldName) {
      customAttribute.setPresenceCondition(
        new content_proto.PresenceCondition().setPresenceTermList([
          new content_proto.PresenceTerm()
            .setFieldName(cattr.conditionFieldName)
            .setFieldValue(cattr.conditionFieldValue),
        ])
      );
    }
    customAttribute.setCustomValueAllowed(cattr.customValueAllowed);
    console.log("Custom Attribute Value Object", customAttribute.toObject());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getMemberCustomAttributeMap()
      .set(cattr.name, customAttribute);
    console.log(
      "Map ..",
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getMemberCustomAttributeMap()
        .get(cattr.name)
    );
  },
  removeMemberCustomAttribute(state, name) {
    console.log("Removing  ...", name);
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getMemberCustomAttributeMap()
      .del(name);
    console.log(
      "Map After removing ..",
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getMemberCustomAttributeMap()
        .get(name)
    );
  },
  addCoachCustomAttribute(state, cattr) {
    console.log("Name and attribute values are ....", cattr);
    var customAttribute = new content_proto.CustomAttribute();
    customAttribute.setName(cattr.name);
    customAttribute.setCustomAttributeType(
      content_proto.CustomAttributeType[cattr.type]
    );
    customAttribute.setAutoCompleteSetting(
      new content_proto.AutoCompleteSetting().setValueList(cattr.setting)
    );
    customAttribute.setCustomAttributePresence(
      content_proto.FieldPresence[cattr.presence]
    );
    customAttribute.setCustomValueAllowed(cattr.customValueAllowed);
    customAttribute.setOrderIndex(cattr.orderIndex);
    customAttribute.setHelpText(cattr.helpText);
    if (cattr.conditionFieldName) {
      customAttribute.setPresenceCondition(
        new content_proto.PresenceCondition().setPresenceTermList([
          new content_proto.PresenceTerm()
            .setFieldName(cattr.conditionFieldName)
            .setFieldValue(cattr.conditionFieldValue),
        ])
      );
    }
    console.log("Custom Attribute Value Object", customAttribute.toObject());
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getCoachSetting()
      .getCoachCustomAttributeMap()
      .set(cattr.name, customAttribute);
    console.log(
      "Map ..",
      state.content
        .getMetaData()
        .getContestMeta()
        .getTeamContestConfig()
        .getCoachSetting()
        .getCoachCustomAttributeMap()
        .get(cattr.name)
    );
  },
  removeCoachCustomAttribute(state, name) {
    console.log("Removing  ...", name);
    state.content
      .getMetaData()
      .getContestMeta()
      .getTeamContestConfig()
      .getCoachSetting()
      .getCoachCustomAttributeMap()
      .del(name);
  },
  setIsRestrictSubmission(state, isRestrictSubmission) {
    state.content
      .getMetaData()
      .getContestMeta()
      .setRestrictSubmission(isRestrictSubmission);
  },
  setTags(state, tags) {
    state.content.getMetaData().setTagsList(tags);
  },
  fetchAllContestSubmissionsStatus(state, status) {
    state.fetchAllContestSubmissionsStatus = status;
  },
  refreshScoreboardStatus(state, status) {
    state.refreshScoreboardStatus = status;
  },
  extendContestForTeasmStatus(state, status) {
    state.extendContestForTeasmStatus = status;
  },
  fetchContestTeamsStatus(state, status) {
    state.fetchContestTeamsStatus = status;
  },
  ScoreDetailsForContestStatus(state, status) {
    state.ScoreDetailsForContestStatus = status;
  },
  dumpContestScoreboardStatus(state, status) {
    state.dumpContestScoreboardStatus = status;
  },
  fetchContestScoreboardAdminStatus(state, status) {
    state.fetchContestScoreboardAdminStatus = status;
  },
  fetchContestScoreboardStatus(state, status) {
    state.fetchContestScoreboardStatus = status;
  },
  setAllowedLanguages(state, allowedLanguages) {
    console.log("Allow ...", allowedLanguages);
    var langs = allowedLanguages.map((lang) => judge_proto.Language[lang]);
    // console.log("langs ...", langs);
    if (
      state.content.getMetaData().getContestMeta() &&
      !state.content.getMetaData().getContestMeta().getLanguageRestriction()
    ) {
      state.content
        .getMetaData()
        .getContestMeta()
        .setLanguageRestriction(new content_proto.LanguageRestriction());
      // console.log("Setting it here if not present", state.content
      //   .getMetaData()
      //   .getContestMeta().toObject())
    }
    state.content
      .getMetaData()
      ?.getContestMeta()
      ?.getLanguageRestriction()
      ?.clearAllowLanguageList();
    state.content
      .getMetaData()
      ?.getContestMeta()
      ?.getLanguageRestriction()
      ?.setAllowLanguageList(langs);
    // console.log("Set state content ..", state.content.getMetaData().getContestMeta().getLanguageRestriction().toObject())
  },
  updateEmailTemplate(state, { key, value }) {
    console.log("Updating email template", key, value);
    var templateMap = state.content
      .getMetaData()
      .getContestMeta()
      .getEmailTemplateIdMap();
    if (!templateMap) {
      templateMap = {};
      state.content
        .getMetaData()
        .getContestMeta()
        .setEmailTemplateIdMap(templateMap);
    }
    state.content
      .getMetaData()
      .getContestMeta()
      .getEmailTemplateIdMap()
      .set(key, value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
