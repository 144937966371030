<template>
  <span v-if="iamAdmin">
    <v-row>
      <v-col>
        <v-tabs
          align-with-title
          color="accent"
          slider-color="accent"
          v-model="tab"
        >
          <v-tab>User List</v-tab>
          <v-tab>User Permission</v-tab>
          <v-tab>User Scope</v-tab>
          <!-- <v-tab>Create customer</v-tab> -->
          <v-tab>Upload MCQs</v-tab>
          <v-tab>Payment Plan</v-tab>
          <!-- <v-tab>Customer</v-tab> -->
          <v-tab>Analytics</v-tab>
          <v-tab>Create coupons</v-tab>
          <v-tab>Test Report</v-tab>
          <v-tab>Assessment settings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- User list-->
            <v-card>
              <v-card-actions>
                <v-btn
                  :loading="toggleloader"
                  :disabled="!valid"
                  color="accent"
                  @click="fetchUsers"
                >
                  fetch Users
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="getVerificationLink"
                      :label="`Get Verification Link: ${getVerificationLink.toString()}`"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-combobox
                      label="Add member email IDs:"
                      multiple
                      chips
                      clearable
                      color="accent"
                      :rules="emailRules"
                      v-model="emails"
                      hint="Press Tab or Enter after each email(s)."
                    >
                      <template v-slot:selection="{ attrs, item }">
                        <v-chip
                          v-bind="attrs"
                          close
                          @click:close="removeEmail(item)"
                          >{{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table :headers="headers" :items="adminUsersInfo">
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.getName() }}</td>
                          <td>{{ item.getId() }}</td>
                          <td>{{ item.getEmail() }}</td>
                          <td>{{ item.getHandle() }}</td>
                          <td>{{ item.getVerified() }}</td>
                          <td>{{ item.getVerificationLink() }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <!-- user Permissions -->
            <v-row>
              <v-col :cols="4">
                <v-text-field label="email" @change="addUser"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="4">
                <v-combobox
                  :value="scopes"
                  :items="scopeTypeKeys"
                  label="User Scope"
                  multiple
                  chips
                  @change="changeScopes"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="4">
                <v-combobox
                  :value="roles"
                  :items="roleTypeKeys"
                  label="Role"
                  chips
                  @change="changeRole"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="accent" @click="updateUser">Save</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="4">
                    <!-- <v-file-input accept="*" label="Select Test Files" v-model="loadtestEmails" @change="loadtestEmailsUpload"></v-file-input> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <v-btn @click="getUsersWithScope">
                  Fetch User with Scopes</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table :headers="scopeHeaders" :items="usersWithScopes">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.getEmail() }}</td>
                      <td>{{ item.getName() }}</td>
                      <td>
                        {{ scopeTypeKeys[item.getScopes().getScopesList()[0]] }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- <v-tab-item> Create customer
				<v-row>
					<v-col>
						<v-text-field label="Customer name" v-model="customerName"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn :loading="createLoading" color="accent" @click="createCustomerAction">Create</v-btn>
					</v-col>
				</v-row>
				<v-row v-if="newCustomerView">
					<v-col>
						Customer id: {{newCustomerView.getId()}}
					</v-col>
				</v-row>
			</v-tab-item> -->
          <!-- <v-tab-item> 
				<v-row>
					<v-col>
						<v-text-field label="Customer id" v-model="customerId"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field label="Admin name" v-model="adminName"></v-text-field>
					</v-col>
					<v-col>
						<v-text-field label="Admin email" v-model="adminEmail"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn color="accent" :loading="addAdminLoading" @click="addAdminAction">Add admin</v-btn>
					</v-col>
				</v-row>
			</v-tab-item> -->
          <v-tab-item>
            <v-row>
              <v-col>
                <v-file-input
                  accept="*"
                  label="Select mcqs csv"
                  v-model="mcqCsv"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="accent" height="35" @click="saveMcqCsv"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  v-if="csvErrorText.length > 0"
                  x-large
                  color="success"
                  class="px-5 py-6 text-none text-body-1 font-weight-medium"
                  download="updated_mcqs.csv"
                  :href="`data:application/octet-stream,${encodeURI(
                    this.csvErrorText
                  )}`"
                >
                  Download CSV with errors
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <!-- Customer Payment Plan-->
            <v-row>
              <v-col>
                <v-text-field
                  label="Customer id"
                  v-model="customerId"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Plan Type"
                  :items="planTypeKeys"
                  v-model="planType"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Add on"
                  :items="addOnKeys"
                  v-model="addOn"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="accent"
                  :loading="addAdminLoading"
                  @click="updatePlan"
                  >Update Plan</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="downlodCustomersCSV">Download CSV</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="getAllPaidCustomersAPI">Fetch Customers</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table :headers="customerHeaders" :items="paidCustomers">
                  <template v-slot:item.name="{ item }">
                    <router-link :to="`/admin/customer/${item.id}`"
                      >{{ item.name || item.id }}
                    </router-link>
                  </template>
                  <template v-slot:item.addOns="{ item }">
                    <v-chip v-for="addOn in item.addOns" :key="addOn" small>{{
                      addOn
                    }}</v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!--- Candidate feedback-->
            <v-row>
              <v-col>
                <v-btn color="accent" height="35" @click="candidateFeedback"
                  >candidate Feedback</v-btn
                >
                <v-data-table
                  :headers="feedbackHeaders"
                  :items="feedbackList"
                  :items-per-page="25"
                  class="elevation-1"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- Create coupons-->
            <v-row>
              <v-col>
                <v-text-field label="Coupon" v-model="coupon"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Count" v-model="count"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :loading="createLoading"
                  color="accent"
                  @click="createCouponsReq"
                  >Create coupons</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="gsUrl">
              <v-col> File url is : {{ gsUrl }} </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Enter URLs (comma separated)"
                  v-model="urlInput"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn text @click="processUrls">Submit URLs</v-btn>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Test Report -->
          <v-tab-item>
            <v-row class="mb-3">
              <v-col>
                <v-text-field
                  label="Test ID"
                  type="number"
                  v-model="testId"
                  class="pa-2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :loading="regenerateReportLoading"
                  color="accent"
                  @click="regenerateReportReq"
                  class="pa-2"
                >
                  Regenerate Report
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Assessment settings -->
          <v-tab-item>
            <v-text-field label="URL" v-model="testURL"></v-text-field>
            <v-btn color="green" @click="fetchDateTime"
              >Fetch Expiry First</v-btn
            >
            <v-card elevation="0" class="pa-5">
              <v-card-title> Test Settings </v-card-title>
              <v-card-text>
                <v-checkbox
                  v-model="testByUrlEnabled"
                  label="Enable Test Invite by URL"
                >
                </v-checkbox>
                <div v-if="testByUrlEnabled">
                  <label for="url-expiry-picker" class="text-h6 black--text">
                    Set Expiry Date for Test URL
                  </label>
                  <div>
                    <v-date-picker
                      id="url-expiry-picker"
                      :allowed-dates="allowedDatesForLink"
                      v-model="testUrlExpiryEpoch"
                      elevation="1"
                    >
                    </v-date-picker>
                  </div>
                  <div>
                    <v-time-picker
                      color="accent"
                      v-model="expiryTime"
                    ></v-time-picker>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-col cols="12" md="6" class="d-flex flex-column text-h6">
              <div class="d-flex flex-row">
                <b>Enable Test URL:</b>
                <div class="pl-3">
                  {{ testByUrlEnabled ? "Yes" : "No" }}
                </div>
              </div>
              <div class="d-flex flex-row">
                <b v-if="testByUrlEnabled">Test URL Expiry:</b>
                <div class="pl-3">
                  {{ testByUrlEnabled ? stringFormatDateTime : "" }}
                </div>
              </div>
              <v-btn color="red" @click="updateEndDateTime"
                >Save date time</v-btn
              >
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import moment, { isDate } from "moment";

export default {
  data: function () {
    return {
      urlInput: "",
      urls: [],
      csvErrorText: "",
      emailRules: [(v) => v == "" || /.+@.+/.test(v) || "E-mail must be valid"],
      tab: null,
      roles: null,
      scopes: null,
      loadtestEmails: null,
      emails: [],
      toggleloader: false,
      valid: true,
      getVerificationLink: false,
      customerName: "",
      customerId: 0,
      adminName: "",
      adminEmail: "",
      createLoading: false,
      addAdminLoading: false,
      regenerateReportLoading: false,
      mcqCsv: null,
      planType: null,
      addOn: null,
      coupon: null,
      count: 0,
      testId: null,
      testByUrlEnabled: false,
      testUrlExpiryEpoch: "",
      expiryTime: null,
      testURL: null,
      feedbackHeaders: [
        { text: "testId", value: "testId" },
        { text: "email", value: "email" },
        {
          text: "Assessment Stars",
          value: "candidateFeedback.assessmentStars",
        },
        { text: "platform Stars", value: "candidateFeedback.platformStars" },
        {
          text: "written feedback",
          value: "candidateFeedback.writtenFeedback",
        },
      ],
      headers: [
        {
          text: "name",
          align: "start",
        },
        {
          text: "id",
          align: "start",
        },
        {
          text: "email",
          align: "start",
        },
        {
          text: "handle",
          align: "start",
        },
        {
          text: "verified",
          align: "start",
        },
        {
          text: "verification link",
          align: "start",
        },
      ],
      scopeHeaders: [
        {
          text: "Email",
          align: "start",
        },
        {
          text: "Name",
          align: "start",
        },
        {
          text: "Scopes",
          align: "start",
        },
      ],
      selectedContentType: "",
      contentUrl: "",
      customerHeaders: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Admin Emails", value: "adminEmails" },
        { text: "Company Domains", value: "emailDomainsList" },
        { text: "Hiring Plan", value: "hiringPlan" },
        { text: "Add Ons", value: "addOns" },
        { text: "Candidates invited so far", value: "inviteCount" },
        { text: "Test created so far", value: "testCount" },
        { text: "Last invite sent On", value: "lastUsedOn" },
      ],
      paidCustomers: [],
    };
  },
  methods: {
    ...mapActions("admin", [
      "getUsers",
      "setUsers",
      "updateProblemSolved",
      "digUsers",
      "createUsers",
      "createCustomer",
      "addAdmin",
      "uploadMcqs",
      "updatePlanType",
      "candidateFeedback",
      "createCoupons",
      "regenerateReport",
      "exportProblems",
    ]),
    ...mapActions("user", [
      "isAdmin",
      "getUsersWithScope",
      "getAllPaidCustomers",
    ]),
    ...mapActions("content", ["fetchMetaData", "updatedAssessmentExpiry"]),
    ...mapMutations("admin", ["setScopes", "setRole"]),
    ...mapMutations("content", ["setExpiry"]),

    processUrls() {
      this.urls = this.urlInput.split(",");
      console.log("Urls are", this.urls);
      this.exportProblems({ urls: this.urls })
        .then(() => {
          alert("Done");
        })
        .catch((error) => {
          console.error("Error exporting problems:", error);
        });
    },
    downlodCustomersCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent +=
        this.customerHeaders.map((header) => header.text).join(",") + "\n";

      this.paidCustomers.forEach((item) => {
        let row = [];
        this.customerHeaders.forEach((header) => {
          if (header.value === "userEmails") {
            row.push(item.userList.map((user) => user.email).join(","));
          } else if (header.value === "addOns") {
            row.push(item.addOns.join(","));
          } else {
            row.push(item[header.value]);
          }
        });
        csvContent += row.join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "paid_customers.csv");
      document.body.appendChild(link); // Required for Firefox

      link.click(); // This will download the data file named "paid_customers.csv".
    },
    async getAllPaidCustomersAPI() {
      // Fetch data from API
      if (!this.planType) {
        window.alert("Select  plan type selected");
        return;
      }
      console.log(
        "Fetching all paid customers",
        this.planTypeKeys,
        this.planType
      );
      const response = await this.getAllPaidCustomers({
        planType: this.planType,
      });
      console.log("response", response.toObject());
      this.paidCustomers = response.getCustomerList().map((c) => ({
        id: c.getId(),
        name: c.getName(),
        emailDomainsList: c.getEmailDomainsList(),
        hiringPlan: this.planTypeKeys[c.getHiringPlan()],
        addOns: c
          .getAddOns()
          .getAddOnList()
          .map((a) => this.addOnKeys[a]),
        inviteCount: c.getInviteCount(),
        testCount: c.getTestCount(),
        lastUsedOn: new Date(c.getLastUsedOn()),
        adminEmails: c
          .getUserList()
          .map((u) => u.getEmail())
          .join(", "),
      }));
    },
    addUser(email) {
      console.log("Email", email);
      this.getUsers({ email }).then((__) => this.setFields());
    },
    fetchUsers() {
      console.log("Fetching ..", this.emails);
      this.digUsers({
        emails: this.emails,
        getVerificationLink: this.getVerificationLink,
      });
    },

    updateSolved() {
      console.log("Updating solved problem");
      this.updateProblemSolved();
    },
    setFields() {
      if (this.user) {
        if (this.user.getScopes())
          this.scopes = this.user
            .getScopes()
            .getScopesList()
            .map((s) => this.scopeTypeKeys[s]);
        else this.scopes = [];
        this.roles = this.roleKeys[this.user.getRole()];
      } else {
        this.scopes = [];
        this.roles = null;
      }
      console.log("scopes, roles", this.scopes, this.roles);
    },
    changeScopes(scope) {
      this.setScopes(scope);
    },
    changeRole(role) {
      this.setRole(role);
    },
    updateUser() {
      this.setUsers().then((__) => this.setFields());
    },
    removeEmail(emailId) {
      this.emails.splice(this.emails.indexOf(emailId), 1);
      this.emails = [...this.emails];
    },
    createCustomerAction() {
      this.createLoading = true;
      this.createCustomer({ name: this.customerName }).then((__) => {
        this.createLoading = false;
      });
    },
    addAdminAction() {
      console.log("cid", this.customerId);
      this.addAdminLoading = true;
      this.addAdmin({
        customerId: this.customerId,
        name: this.adminName,
        email: this.adminEmail,
      }).then((__) => {
        this.addAdminLoading = false;
      });
    },

    updatePlan() {
      if (!this.planType || !this.customerId) {
        window.alert("planType or customerId is missing");
        return;
      }
      console.log("cid", this.customerId);
      this.addAdminLoading = true;
      this.updatePlanType({
        customerId: this.customerId,
        type: this.planType,
        addOn: this.addOn,
      })
        .then((__) => {
          this.addAdminLoading = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Plan updated successfully",
            type: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: err.message,
            type: "error",
          });
        });
    },

    loadtestEmailsUpload() {
      this.loadtestEmails.text().then((content) => {
        this.createUsers({
          emails: content,
        }).then((__) => {});
      });
    },
    async saveMcqCsv() {
      await this.mcqCsv.text().then((content) => {
        this.uploadMcqs({
          mcqs: content,
        }).then((res) => {
          this.mcqCsv = null;
          this.csvErrorText = res.getMcqCsv();
        });
      });
    },
    createCouponsReq() {
      this.createCoupons({ coupon: this.coupon, count: this.count });
    },
    regenerateReportReq() {
      this.regenerateReportLoading = true;
      this.regenerateReport({ testId: this.testId }).then((__) => {
        this.regenerateReportLoading = false;
      });
    },
    updateSelectedContentType(type) {
      this.selectedContentType = type;
    },
    allowedDatesForLink(selection) {
      // yyyy-mm-dd is the internal rep of date in v-date-picker
      // allowed date should be greater than today's date
      const selectedDate = new Date(selection);
      const yesterday = new Date(Date.now() - 86400000);
      return selectedDate >= yesterday;
    },
    updateEndDateTime() {
      var epoch = this.calculateDatetime(
        this.testUrlExpiryEpoch,
        this.expiryTime
      );
      console.log("End epoch ", epoch);
      this.setExpiry(epoch);
      this.updatedAssessmentExpiry({ url: this.testURL });
    },
    calculateDatetime(date, time) {
      if (time && date) {
        var datetime = date + " " + time;
        return moment(datetime, "YYYY-MM-DD H:mm").unix();
      }
    },
    fetchDateTime() {
      this.fetchMetaData({ url: this.testURL }).then((res) => {
        console.log("res is ...", res.toObject());
        var expiryL = res.getMetaData().getTestMeta().getTestUrlExpiry() / 1000;
        console.log("Expiry is...", expiryL);
        this.testUrlExpiryEpoch = moment.unix(expiryL).format("YYYY-MM-DD");
        this.expiryTime = moment.unix(expiryL).format("HH:mm");
      });
    },
  },
  computed: {
    ...mapState("admin", [
      "user",
      "adminUsersInfo",
      "newCustomerView",
      "feedback",
      "gsUrl",
    ]),
    ...mapState("user", ["iamAdmin", "usersWithScopes"]),
    ...mapState("conent", ["getExpiry"]),
    ...mapGetters("admin", [
      "roleKeys",
      "roleTypeKeys",
      "scopeTypeKeys",
      "planTypeKeys",
      "addOnKeys",
      "contentTypeKeys",
    ]),
    feedbackList() {
      return this.feedback.map((f) => f.toObject());
    },
    stringFormatDateTime() {
      var datetime = this.testUrlExpiryEpoch + " " + this.expiryTime;
      return moment(datetime, "YYYY-MM-DD H:mm");
      // return new Date(this.testUrlExpiryEpoch).toDateString() + " "
    },
  },
  watch: {
    roles: function (r) {
      console.log("Role", r, this.roleTypeKeys);
    },
  },
  mounted() {
    this.isAdmin();
  },
};
</script>
